import autoBind from "auto-bind"
import React from "react"
import { Box, Center, Heading } from "@chakra-ui/react"
import CityInput from "./CityInput"
import CityName from "./CityName"
import CurrentTemp from "./CurrentTemp"
import WeatherDescription from "./WeatherDescription"
import WeatherIcon from "./WeatherIcon"
import Wind from "./Wind"
import "animate.css"

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: "",
    }

    autoBind(this)
  }

  handleInput(data) {
    this.setState({
      data: data,
    })
  }

  render() {
    return (
      <Box h="1000px" p="5vh" bg="purple.50">
        <Heading noOfLines={1}>
          <Center>Weather App</Center>
        </Heading>
        <Center>
          <Box my="5vh">
            <CityInput handleInput={this.handleInput} />
            {this.state.data ? (
              <Box
                className="animate__animated animate__bounceIn"
                borderWidth="1px"
                boxShadow="base"
                p="6"
                rounded="md"
                bg="white"
              >
                <CityName data={this.state.data} />
                <WeatherIcon data={this.state.data} />
                <CurrentTemp data={this.state.data} />
                <WeatherDescription data={this.state.data} />
                <Wind data={this.state.data} />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Center>
      </Box>
    )
  }
}

export default App
