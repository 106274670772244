import React from "react"
import autoBind from "auto-bind"
import {
  Input,
  Button,
  SimpleGrid,
  Center,
  Box,
  Spinner,
} from "@chakra-ui/react"
import { getWeather } from "../utils/getDataApi"
import { FaSearch } from "react-icons/fa"

class CityInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userInput: "",
      isError: false,
      loading: false,
    }
    autoBind(this)
  }

  //  fungsi yang memantau setiap perubahan dalam input
  handleChange = (e) => {
    this.setState({
      userInput: e.target.value,
    })
  }

  // kirim data ke parents setelah tombol di klik
  sendDataToParent = async (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    let cityToSearch = this.state.userInput
    try {
      let data = await getWeather(cityToSearch)
      this.props.handleInput(data)
      this.setState({ isError: false, loading: false })
    } catch (error) {
      this.setState({ isError: true, loading: false })
    }
  }

  render() {
    return (
      <Box mb="5vh">
        {this.state.isError ? "city not found" : ""}
        <Center>
          <form onSubmit={this.sendDataToParent}>
            <SimpleGrid columns={{ sm: "1", md: "2" }} spacing={2}>
              <Input
                bg="white"
                onChange={this.handleChange}
                name="city"
                type="text"
                placeholder="ex: Bali"
                autoFocus={true}
              />
              <Button
                colorScheme="pink"
                disabled={this.state.loading && true}
                leftIcon={this.state.loading ? <Spinner /> : <FaSearch />}
                type="submit"
              >
                Find
              </Button>
            </SimpleGrid>
          </form>
        </Center>
      </Box>
    )
  }
}

export default CityInput
