import React from "react"
import { Text, Box, Center, Heading } from "@chakra-ui/react"

const CityName = ({ data }) => {
  // fungsi untuk mendapatkan
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" })
  return (
    <Center>
      <Box>
        <Heading as="h2" size="md">
          {data.city ? data.city.name + ", " : ""}

          {data.city ? regionNames.of(data.city.country) : ""}
        </Heading>
      </Box>
    </Center>
  )
}

export default CityName
