import axios from "axios"
const key = process.env.REACT_APP_SECRET_API_KEY

// const getWeather = async (city) => {
//   const response = await axios.get(url)
//   return response.data
// }

const getWeather = async (city) => {
  const response = await axios.get(
    `https://api.openweathermap.org/data/2.5/forecast?q=${city}&units=metric&appid=${key}`
  )
  return response.data
}

export { getWeather }
