import React from "react"
import { Image, Box, Center } from "@chakra-ui/react"

const WeatherIcon = ({ data }) => {
  let codeImage = ""
  let imageSource = ""
  if (data.list) {
    codeImage = data.list[0].weather[0].icon
    imageSource = `http://openweathermap.org/img/wn/${codeImage}@2x.png`
  }

  return (
    <Box my="2vh">
      <Center>
        <Image src={imageSource}></Image>
      </Center>
    </Box>
  )
}

export default WeatherIcon
