import React from "react"
import { Box, Text, Center, Image } from "@chakra-ui/react"

const Wind = ({ data }) => {
  let wind = ""
  if (data.list) {
    wind = Math.round(data.list[0].wind.speed) + "m/s"
  }
  return (
    <Box my="2vh">
      <Center>
        <Box my="1vh" boxSize="20px">
          {wind ? <Image src="./wind.png"></Image> : ""}
        </Box>
      </Center>
      <Center>
        <Text>{wind}</Text>
      </Center>
    </Box>
  )
}

export default Wind
