import React from "react"
import { Text, Center } from "@chakra-ui/react"

const WeatherDescription = ({ data }) => {
  let description = ""
  if (data.list) {
    description = data.list[0].weather[0].description
  }
  return (
    <Center>
      <Text fontSize="md">{description}</Text>
    </Center>
  )
}

export default WeatherDescription
