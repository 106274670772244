import React from "react"
import { Text, Center } from "@chakra-ui/react"

const CurrentTemp = ({ data }) => {
  let temp = ""
  if (data.list) {
    temp = Math.round(data.list[0].main.temp) + "°"
  }
  return (
    <Center>
      <Text fontSize="5xl">{temp}</Text>
    </Center>
  )
}
export default CurrentTemp
